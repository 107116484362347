import './App.css';
import React, { Component } from 'react';

class App extends Component {

  constructor(props) {
    super(props);

    this.clickNewWorld = this.clickNewWorld.bind(this)
    this.clickLandWorld = this.clickLandWorld.bind(this)
    this.clickTakeOff = this.clickTakeOff.bind(this)
    this.clickNewArea = this.clickNewArea.bind(this)
    this.clickReturnArea = this.clickReturnArea.bind(this)

    this.state = {
      worlds: [],
      world: {},
      world_path: '',
      world_image_path: '',
      is_loading: false,
      error: null,
      has_landed: false,
      world_index: 0,
      area_index: 0,
      ship_landed: 0,
      image_loaded: false,
    }

  }

  pathJoin(parts){
    var separator = '/';
    var replace   = new RegExp(separator+'{1,}', 'g');
    return parts.join(separator).replace(replace, separator);
  } 

  shuffle(array) {
    var currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  async pickRandomWorld() {
    const response = await fetch('./world_index.json');
    const worlds_index = await response.json();

    worlds_index.worlds = this.shuffle(worlds_index.worlds);
      
    var world_path = this.pathJoin(['.', 'worlds', String(worlds_index.worlds[0]) + ".json"]);
    var image_path = this.pathJoin(['.', 'worlds', 'images', String(worlds_index.worlds[0]) + ".png"]);

    this.setState({ worlds: worlds_index.worlds, world_path: world_path, world_image_path: image_path});

    this.readWorld(world_path);
  }

  async readWorld(world_path) {
    const response = await fetch(world_path);

    try {
      const world = await response.json();
      this.setState({ world: world, is_loading: false, image_loaded: false });
    } catch(error) {
      this.setState({ error, isLoading: false})
    }
  }

  clickNewWorld() {
    this.setState({ is_loading: true });

    var world_index = this.state.world_index + 1;
    if (world_index >= this.state.worlds.length) {
      world_index = 0;
    }

    var world_path = this.pathJoin(['.', 'worlds', String(this.state.worlds[world_index]) + ".json"]);
    var image_path = this.pathJoin(['.', 'worlds', 'images', String(this.state.worlds[world_index]) + ".png"]);

    this.setState({ world_path: world_path, world_image_path: image_path, has_landed: false, world_index, image_loaded: false});

    this.readWorld(world_path);
  }

  clickLandWorld() {
    this.setState({ has_landed: true, ship_landed: this.state.area_index, image_loaded: false })
  }

  clickNewArea() {
    var area_index = this.state.area_index;
    area_index = (area_index + 1) % this.state.world.areas.length;
    this.setState({ area_index, image_loaded: false });
  }

  clickReturnArea() {
    var area_index = this.state.area_index;
    area_index = (area_index - 1)
    if (area_index < 0) {
      area_index += this.state.world.areas.length;
    }
    this.setState({ area_index, image_loaded: false });
  }

  clickTakeOff() {
    this.setState({ has_landed: false, image_loaded: false })
  }

  componentDidMount() {
    this.setState({ is_loading: true });
    this.pickRandomWorld();
  }

  showReturn() {
    if (this.state.ship_landed == this.state.area_index) {
      return(<button onClick={this.clickTakeOff} className="Button-default">Take Off</button>)
    }
  }

  showImage(source) {
    return ( 
        <React.Fragment>
          <div style={{display: this.state.image_loaded ? "none" : "block"}} className="Spinning-box">
            <span className="Spinning-helper">
              <img src="./spinner.png" className="Spinning-wheel"/>
            </span>
          </div>
          <div style={{display: this.state.image_loaded ? "block" : "none"}} className="Illustration-box">
            <img style={this.state.image_loaded ? {} : {display : 'none'}} src={source} className="Illustration" onLoad={() => this.setState({image_loaded: true})}/>
          </div>
        </React.Fragment>
      )
  }

  render() {
    if (this.state.error) {
      return (
        <div className="App">
          <header className="App-header">
            <p className="Title">Error: {this.state.error.message}</p>
          </header>
        </div>
      );
    } else if (this.state.is_loading) {
      return (
        <div className="App">
          <header className="App-header">
            <p>Loading...</p>
          </header>
        </div>
      );
    } else if (this.state.has_landed) {
      return (
        <div className="App">
          <div className="App-header">
            <header>
              <p className="Title">
              {this.state.world.areas[this.state.area_index].name}
              </p>
            </header>
            {this.showImage(this.pathJoin(['.', 'worlds', 'images', String(this.state.world.areas[this.state.area_index].guid) + ".png"]))}
            <div className="Text-container">
              <div className="Desc-container">
                <div className="Para-container">
                  <p className="Description">
                    {this.state.world.areas[this.state.area_index].long_desc}
                  </p>
                  {this.state.ship_landed == this.state.area_index
                    ? <p className="Description">Your ship is here, hovering wordlessly above the ground.</p>
                    : ''}
                </div>
              </div>
              <div className="Button-container">
                <button onClick={this.clickNewArea} className="Button-default">Go Forwards</button>
                {this.state.ship_landed == this.state.area_index
                  ? <button onClick={this.clickTakeOff} className="Button-default">Take Off</button>
                  : ''}
                <button onClick={this.clickReturnArea} className="Button-default">Go Backwards</button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="App">
          <div className="App-header">
            <header>
              <p className="Title">
                This is Arcscriber, an AI adventure through the stars.
              </p>
            </header>
            {this.showImage(this.pathJoin(['.', 'worlds', 'images', String(this.state.worlds[this.state.world_index]) + ".png"]))}
              <div className="Text-container">
                <div className="Desc-container">
                  <div className="Para-container">
                    <p className="Description">
                      From your ship you see {this.state.world.space_desc}
                    </p>
                  </div>
                </div>
              <div className="Button-container">
                <button onClick={this.clickNewWorld} className="Button-default">Explore New World</button>
                <button onClick={this.clickLandWorld} className="Button-default">Land Your Ship</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default App;
